<!--
 * @Author: Neko
 * @Date: 2021-01-11 16:08:20
 * @LastEditTime: 2021-10-16 15:07:17
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="order-detail__container">
    <div class="order-detail__wrap">

      <div class="order__container-header">
        <div class="header">
          <div class="header__wrap">
            <h2 class="header__title">
              <span class="title">{{ this.$t('order.PersonalCenter') }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="order-detail__container">
        <div class="left">
          <div class="base__section">
            <div class="base__header">
              <div class="header__left">
                {{ this.$t('order.currentstate') }}：{{ statusList[orderData.orderStatus] }}
              </div>

              <div class="header__right">
                <el-button v-if="orderData.orderStatus === 0" type="primary" class="button topay">{{ this.$t('order.ToPay') }}
                  <div class="topay-hover">
                    <el-button type="primary" round @click="paypalHandle">{{ this.$t('order.payPalToPay') }}</el-button>
                    <el-button type="primary" round @click="checkoutHandle">{{ this.$t('order.checkoutToPay') }}</el-button>
                  </div>
                </el-button>
                <el-button v-if="orderData.orderStatus === 0 " type="primary" class="button" @click="onCancelOrder">{{ this.$t('order.cancelorder') }}</el-button>
                <el-button v-if="orderData.orderStatus === 2" type="primary" class="button" @click="onFinishOrder">{{ this.$t('order.Confirmreceipt') }}</el-button>

              </div>
            </div>

            <div class="base__content">
              <dl class="base__content-item">
                <dt>{{ this.$t('order.number') }}：</dt>
                <dd>{{ orderData.orderNo }}</dd>
              </dl>

              <dl class="base__content-item">
                <dt>{{ this.$t('order.time') }}：</dt>
                <dd>{{ orderData.orderCreateTime }}</dd>
              </dl>
            </div>
          </div>

          <div class="base__section">
            <div class="base__header">
              <div class="header__left">
                {{ this.$t('order.Receivinginformation') }}
              </div>
            </div>

            <div class="base__content">
              <el-row>
                <el-col :span="8">
                  <dl class="base__content-item">
                    <dt>{{ this.$t('order.consignee') }}：</dt>
                    <dd>{{ orderData.deliveryInformationVO && orderData.deliveryInformationVO.consignee }}</dd>
                  </dl>
                </el-col>

                <el-col :span="8">
                  <dl class="base__content-item">
                    <dt>{{ this.$t('order.phonenumber') }}：</dt>
                    <dd>{{ orderData.deliveryInformationVO && orderData.deliveryInformationVO.mobile }}</dd>
                  </dl>
                </el-col>

                <el-col :span="8">
                  <dl class="base__content-item">
                    <dt>{{ this.$t('order.country') }}：</dt>
                    <dd>{{ orderData.orderInfo && orderData.orderInfo.countryName }}</dd>
                  </dl>
                </el-col>
              </el-row>

              <el-row class="row-margin">
                <el-col :span="24">
                  <dl class="base__content-item">
                    <dt>{{ this.$t('order.Detailedaddress') }}：</dt>
                    <dd>{{ orderData.orderInfo && orderData.orderInfo.receiveDetail }}</dd>
                  </dl>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="base__section bg">
            <div class="base__header">
              <div class="header__left">
                {{ this.$t('order.Productinformation') }}
              </div>
            </div>

            <div class="base__content">
              <GoodsItem
                v-for="item in (goodsData.cartGoodsList || [])"
                :key="item.id"
                :data="item"
                :show-operation="false"
                :currency="orderData.currencyUnit"
              />
            </div>
          </div>
        </div>

        <div class="right">
          <div class="base__section">
            <div class="base__header">
              <div class="header__left">
                {{ this.$t('order.currentstate') }}：{{ orderData.paymentType === 1 ? $t('order.paid') : $t('order.unpaid') }}
              </div>
            </div>

            <div class="base__content">
              <dl class="base__content-item">
                <dt>{{ this.$t('order.Commodityamount') }}：</dt>
                <dd>{{ orderData.currencyUnit }}{{ $convertNumberFloat(orderData.goodsTotal || 0) }}</dd>
              </dl>

              <dl class="base__content-item">
                <dt>{{ this.$t('order.Freightamount') }}：</dt>
                <dd>{{ orderData.currencyUnit }}{{ $convertNumberFloat(orderData.freight || 0) }}</dd>
              </dl>

              <dl class="base__content-item">
                <dt>{{ this.$t('order.OrderAmount') }}：</dt>
                <dd>{{ orderData.currencyUnit }}{{ $convertNumberFloat(orderData.paymentPrice || 0) }}</dd>
              </dl>
            </div>
          </div>

          <div v-if="orderData.orderStatus !== 0" class="base__section">
            <div class="base__header">
              <div class="header__left">
                {{ this.$t('order.Logisticsinformation') }}
              </div>
            </div>

            <div class="base__content">
              <dl class="base__content-item">
                <dt>{{ this.$t('order.logisticscompany') }}：</dt>
                <dd>{{ (orderData).companyName }}</dd>
              </dl>

              <dl class="base__content-item">
                <dt>{{ this.$t('order.LogisticsNo') }}：</dt>
                <dd>{{ (orderData.logisticsResult || {}).logisticCode }}</dd>
              </dl>

              <div class="base__step">
                <el-steps v-if="orderData.logisticsResult" direction="vertical" :active="0">
                  <el-step v-for="(item, index) in orderData.logisticsResult.traces" :key="index + 'trace'">
                    <div slot="description" class="description">
                      <p class="time">{{ item.acceptTime }}</p>
                      <p class="text">{{ item.acceptStation }}</p>
                    </div>
                  </el-step>
                </el-steps>

                <template v-else>
                  <div class="empty">
                    <div class="img">
                      <img src="@/assets/images/no-trace.png">
                    </div>

                    <div class="text">{{ this.$t('order.Nologisticsinformation') }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="detail__info">
        <div class="action-bar">
          <div>
            <el-button v-if="orderData.orderStatus === 0" type="primary" class="text-button" @click="onClickPayBtn">去付款</el-button>
            <el-button v-if="orderData.orderStatus === 0 || orderData.orderStatus === 1" class="text-button" @click="onCancelOrder">取消订单</el-button>
            <el-button v-if="orderData.orderStatus === 2" class="text-button" @click="onFinishOrder">确认收货</el-button>
          </div>
        </div>
        <div class="steps-wrap">
          <el-steps class="steps" :active="listActive" finish-status="success" process-status="wait" align-center>
            <el-step
              v-for="(item, index) in orderData.list"
              :key="index"
              :title="item.title"
              :description="item.date"
            />
          </el-steps>
        </div>

        <section v-if="orderData.orderStatus === 0" class="pay__section">
          <div class="header">
            <h3 class="title">支付方式</h3>
          </div>

          <div class="content">
            <ul class="pay__list">
              <li
                v-for="item in orderData.payList"
                :key="item.id"
                class="item"
                :class="{ active: payType === item.id }"
                @click="onClickPayItem(item.id)"
              >
                <a class="link" href="javascript:void(0)">
                  {{ item.name }}
                  <i class="icon el-icon-check" />
                </a>
              </li>
            </ul>
          </div>
        </section>

        <div v-if="orderData.orderStatus === 2">
          <div v-if="traces && traces.length" class="steps-horizon">
            <el-steps :active="1" direction="vertical">
              <el-step v-for="(item,index) in traces" :key="index">
                <template #description>
                  <div class="step-content">
                    <span class="label">最新</span>

                    <div class="time">
                      {{ item.acceptTime }}
                    </div>

                    <div class="description">
                      {{ item.acceptStation }}
                    </div>
                  </div>
                </template>
              </el-step>
            </el-steps>
          </div>
          <div v-else class="no-traces">暂无物流信息</div>
        </div>
      </div>

      <div class="delivery__info">
        <div class="delivery__item">
          <h3 class="title">收货人信息</h3>

          <div class="wrap">
            <dl class="info">
              <dt>收货人：</dt>
              <dd>{{ orderData.deliveryInformationVO && orderData.deliveryInformationVO.consignee }}</dd>
            </dl>

            <dl class="info">
              <dt>手机号码：</dt>
              <dd>{{ orderData.deliveryInformationVO && orderData.deliveryInformationVO.mobile }}</dd>
            </dl>

            <dl class="info">
              <dt>收货地址：</dt>
              <dd>{{ orderData.deliveryInformationVO && orderData.deliveryInformationVO.address }}</dd>
            </dl>
          </div>
        </div>

        <div class="delivery__item">
          <h3 class="title">配送信息</h3>

          <div class="wrap">
            <dl class="info">
              <dt>配送方式：</dt>
              <dd>{{ orderData.claimGoods === 0 ? '平台配送' : '买家自提' }}</dd>
            </dl>
          </div>
        </div>

        <div class="delivery__item">
          <h3 class="title">支付方式</h3>

          <div class="wrap">
            <dl class="info">
              <dt>付款方式：</dt>
              <dd>{{ orderData.payMethodName }}</dd>
            </dl>
          </div>
        </div>

        <div class="delivery__item">
          <h3 class="title">订单详情</h3>

          <div class="wrap">
            <dl class="info">
              <dt>订单编号：</dt>
              <dd>{{ orderData.orderNo }}</dd>
            </dl>

            <dl class="info">
              <dt>下单时间：</dt>
              <dd>{{ orderData.orderCreateTime }}</dd>
            </dl>

            <dl v-if="orderData.payTime" class="info">
              <dt>支付时间：</dt>
              <dd>{{ orderData.payTime }}</dd>
            </dl>

            <dl v-if="orderData.endTime" class="info">
              <dt>取消时间：</dt>
              <dd>{{ orderData.endTime }}</dd>
            </dl>
          </div>
        </div>
      </div>

      <div class="goods__info">
        <h3 class="title">商品清单</h3>

        <div class="goods-list">
          <GoodsTable :data="goodsData" />
        </div>

        <div class="sum__container">
          <dl class="list">
            <dt class="name">总商品金额：</dt>
            <dd class="count">{{ $convertNumberFloat(orderData.goodsTotal || 0) }}  </dd>
          </dl>
          <dl class="list">
            <dt class="name">运费：</dt>
            <dd class="count">{{ $convertNumberFloat(orderData.freight || 0) }}</dd>
          </dl>
          <dl class="list">
            <dt class="name">税费：</dt>
            <dd class="count">{{ $convertNumberFloat(orderData.taxation || 0) }}</dd>
          </dl>
          <dl v-if="orderData.couponPriceBig !== 0" class="list">
            <dt class="name">优惠金额：</dt>
            <dd class="count">{{ $convertNumberFloat(orderData.couponPriceBig || 0) }}</dd>
          </dl>
          <dl class="list">
            <dt class="name">合计总额：</dt>
            <dd class="count bold">{{ $convertNumberFloat(orderData.paymentPrice || 0) }}</dd>
          </dl>
        </div>
      </div> -->

      <el-dialog :visible="isShowDialog" width="30%" :title="$t('order.cancelorder')" @close="onCloseDialog">
        <el-form ref="form" :model="formData" :rules="formDataRuler" class="cancel-form" label-width="150px" label-position="right">
          <el-form-item :label="$t('order.Reasonforordercancellation') + ':'" prop="orderCancelReason">
            <el-select v-model="formData.orderCancelReason" :placeholder="$t('order.Pleaseselect')">
              <el-option :label="$t('order.Wrongorder')+ '/' + $t('order.nowant')" :value="$t('order.Wrongorder')+ '/' + $t('order.nowant')" />
              <el-option :label="$t('order.Outstock')" :value="$t('order.Outstock')" />
              <el-option :label="$t('order.Deliverytimetoolong')" :value="$t('order.Deliverytimetoolong')" />
              <el-option :label="$t('order.other')" :value="$t('order.other')" />
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="formData.orderCancelReason === $t('order.other')"
            prop="orderCancelRemarks"
            :label="$t('order.remarks') + ':'"
          >
            <el-input
              v-model="formData.orderCancelRemarks"
              style="width: 200px"
              type="textarea"
              :rows="2"
              :placeholder="$t('order.Pleaseentercomments')"
            />
          </el-form-item>
          <el-form-item class="buttons">
            <el-button type="primary" class="button" @click="onCancel">{{ $t('order.determine') }}</el-button>
            <el-button class="button white" @click="onCloseDialog">{{ $t('order.cancel') }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <PaymentDialog :visible="isShowPaySuccessDialog" :order-id="id" :pay-type="payType" :price="orderData.paymentPrice+''" @success="onClickSuccessBtn" @cancel="onClickCancelBtn" />

    <ResultDialog :visible="isShowResultDialog" />
    <CheckoutPay :checkout-info="checkoutOption" @closeCheckout="closeCheckoutDialog" />
    <OrderForm :form-data="payPalForm" :action="action" /></div>
</template>

<script>
// import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue'
// import GoodsTable from '@/components/Common/GoodsTable.vue'
import CheckoutPay from '@/components/CheckoutPay'
import OrderForm from '@/components/OrderForm'
import PaymentDialog from '@/components/Common/PaymentDialog.vue'
import GoodsItem from '@/components/Common/GoodsItem.vue'
import ResultDialog from '@/components/Common/ResultDialog.vue'

import useChangeBackground from '@/composition/useChangeBackground.js'
import { reactive, ref, toRefs, toRef, watch, computed } from '@vue/composition-api'

import { useRequest } from '@/utils/request'

import { MessageBox, Message } from 'element-ui'
import session from '@/utils/session'

export default {
  name: 'OrderDetail',
  components: {
    // Breadcrumbs,
    // GoodsTable,
    PaymentDialog,
    GoodsItem,
    ResultDialog,
    CheckoutPay,
    OrderForm
  },

  setup(props, { root }) {
    useChangeBackground('#F6F6F6')

    const { $route, $router } = root

    const $t = root.$jst

    const form = ref(null)

    const data = reactive({
      id: $route.query.orderid,
      orderData: {},
      traces: [],
      goodsData: {},
      breadcrumbs: [
        { name: $t('order.Allorders'), id: 'all' },
        { name: $t('order.Orderdetails'), id: '' }],
      isShowDialog: false,
      formData: {
        orderCancelReason: '',
        orderCancelRemarks: ''

      },
      payPalForm: {},
      action: process.env.NODE_ENV === 'production' ? 'https://www.paypal.com/cgi-bin/webscr' : 'https://www.sandbox.paypal.com/cgi-bin/webscr',
      checkoutOption: {
        checkoutPayDialog: false,
        publicKey: '',
        url: encodeURIComponent(process.env.VUE_APP_BASE_URL),
        token: session.get('token') || '',
        orderId: $route.query.orderid
      },

      formDataRuler: {
        orderCancelReason: { required: true, message: $t('order.Pleaseselect'), trigger: 'change' },
        orderCancelRemarks: { required: true, message: $t('order.Pleaseinput'), trigger: 'blur' }
      },

      payType: 0,
      listActive: 0,

      isShowPaySuccessDialog: false,

      statusList: computed(() => ({
        0: $t('order.Tobepaid'), // 待付款
        1: $t('order.Tobedelivered'), // 待配送
        2: $t('order.Tobereceived'), // 待收货
        3: $t('order.Completed'), // 交易完成
        4: $t('order.Tobedelivered'), // 待自提
        '-1': $t('order.Cancelled'), // 已取消
        '-2': $t('order.Cancelled'), // 取消中
        '-3': $t('order.Refundcomplete'), // 退款完成
        '-4': $t('order.Applyingrefund') // 退款申请中
      })),

      isShowResultDialog: false
    })

    const { data: ordersData, fetch: detailFetch } = useRequest('order/orderDetail', {
      data: {
        orderId: data.id
      },

      initialData: toRef(data, 'orderData')
    })

    watch(() => ordersData.value, res => {
      data.payType = res.payList[0]?.id || ''

      res.list.map((item, index) => {
        if (item.type === 1) {
          data.listActive = index + 1
        }
      })

      data.isShowResultDialog = root.$route.query.is_result === '1'
    })

    watch(() => root.$route, () => {
      detailFetch()
    })

    const { fetch: cancelOrderFetch } = useRequest('order/orderCancel', {
      data: computed(() => ({
        id: $route.query.orderid,
        orderCancelRemarks: data.formData.orderCancelRemarks,
        refundDoc: data.formData.orderCancelReason
      })),
      immediate: false,
      onSuccess: (res) => {
        const { code } = res
        if (code === 200) {
          data.isShowDialog = false
          detailFetch()
        }
      }
    })

    const { fetch: finishOrderFetch } = useRequest('order/orderFinish', {
      data: computed(() => ({
        orderId: $route.query.orderid
      })),
      immediate: false,
      onSuccess: (res) => {
        const { code } = res
        if (code === 200) {
          detailFetch()
        }
      }
    })

    watch(() => data.orderData, (val) => {
      if (val.logisticsResult) {
        data.traces = val.logisticsResult.traces.reverse()
      }
      data.goodsData = { cartGoodsList: val.orderItemVOList }
    })

    const onClickBreadcrumbs = (id) => {
      if (id.length === 0) {
        $router.push({ path: '/profile' })
      }
    }

    const onCancelOrder = () => {
      data.isShowDialog = true
    }

    const onCloseDialog = () => {
      data.isShowDialog = false
      data.formData.orderCancelRemarks = ''
      data.formData.orderCancelReason = ''
    }

    const onCancel = () => {
      form.value.validate(isValid => {
        if (isValid) {
          if (data.orderCancelReason !== $t('order.other')) {
            data.formData.orderCancelRemarks = data.formData.orderCancelReason
          }
          cancelOrderFetch()
        }
      })
    }

    const onFinishOrder = () => {
      MessageBox.confirm($t('order.Areyoureceivegoods'), {
        confirmButtonText: $t('order.determine'),
        cancelButtonText: $t('order.cancel')
      }).then(() => {
        finishOrderFetch()
      }).catch(() => {
      })
    }

    const { data: pacyPayResult } = useRequest('order/handlePacyPayAction', {
      data: computed(() => ({
        orderId: $route.query.orderid,
        url: window.location.protocol + '//' + window.location.host
      })),

      immediate: false
    })

    watch(() => pacyPayResult.value, res => {
      try {
        const { apiUrl, ...params } = JSON.parse(res)

        const formAttr = [
          ['id', 'submit-form'],
          ['action', apiUrl],
          ['method', 'POST'],
          ['style', 'display: none'],
          ['content-type', 'multipart/form-data']
        ]
        const form = document.createElement('form')

        formAttr.forEach(([key, value]) => {
          form.setAttribute(key, value)
        })

        const createInput = attrs => {
          const input = document.createElement('input')

          Object.entries(attrs).forEach(([key, value]) => {
            input.setAttribute(key, value || '')
          })

          return input
        }

        const fragment = document.createDocumentFragment()

        Object.entries(params).forEach(([key, value]) => {
          fragment.appendChild(createInput({
            name: key,
            value: value
          }))
        })

        form.appendChild(fragment)

        const button = document.createElement('button')
        button.type = 'submit'

        form.appendChild(button)

        document.body.appendChild(form)

        form.submit()
      } catch (e) {
        return Message.error('解析 JSON 错误')
      }
    })

    const onClickPayItem = index => {
      data.payType = index
    }

    // const onClickPayBtn = _ => {
    //   // pacyPayAction()
    //   // TODO: 这里需要判断支付方式调用相应的支付

    //   console.log(_, '22')
    // }

    const onClickSuccessBtn = _ => {
      detailFetch()
      data.isShowPaySuccessDialog = false
    }

    const onClickCancelBtn = _ => {
      data.isShowPaySuccessDialog = false
    }
    // paypal 接口函數
    const { fetch: getPayPalPayInfo } = useRequest('order/getPayPalPayInfo', {
      data: computed(() => (
        { orderId: data.id,
          url: window.location.origin,
          jumpUrl: `/order/detail` })

      ),
      immediate: false,
      onSuccess: (res) => {
        const formData = JSON.parse(res.data)
        // 这里交给vue响应式维护 FROM 数据，手动提交表单
        data.payPalForm = formData
      },
      onFail: (err) => {
        console.log(err, 'err')
      }
    })

    // checkout 接口函數
    const { fetch: getCheckoutPayInfo } = useRequest('order/getCheckoutPayInfo', {
      data: computed(() => (
        { orderId: data.id
        })
      ),
      immediate: false,
      onSuccess: (res) => {
        const { publicKey } = res.data
        // const formData = JSON.parse(res.data)
        // 这里交给vue响应式维护 FROM 数据，手动提交表单
        // data.formData = formData
        data.checkoutOption.publicKey = publicKey
        data.checkoutOption.checkoutPayDialog = true
      },
      onFail: (err) => {
        console.log(err, 'err')
      }
    })
    // 点击去PAYPAL支付
    const paypalHandle = () => {
      getPayPalPayInfo()
    }
    // 点击去chekcout支付
    const checkoutHandle = () => {
      getCheckoutPayInfo()
    }
    // 关闭chekcout 对话框
    const closeCheckoutDialog = () => {
      data.checkoutOption.checkoutPayDialog = false
    }
    // 监听 ifrema 消息事件
    window.addEventListener('message', (e) => {
      if (e.data.isSuccess) {
        // TODO: 关闭对话框，从当前页面跳转到订单页面
        data.checkoutOption.checkoutPayDialog = false
        $router.push({
          path: '/order/detail',
          query: {
            orderid: data.orderId
          }
        })
      }
    })
    return {
      ...toRefs(data),
      form, closeCheckoutDialog,
      paypalHandle, checkoutHandle,
      onClickBreadcrumbs,
      onCancelOrder,
      onCloseDialog,
      onCancel,
      onFinishOrder,
      onClickPayItem,
      onClickSuccessBtn,
      onClickCancelBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail__wrap {
  min-width: 1200px;
  width: 96.9%;
  max-width: 1600px;
  border-radius: 8px;
  // padding: 24px 0 60px;
  margin: 0 auto;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
  // background: #fff;

  .order-detail__container {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    padding: 20px 0;
    margin: 0 auto;

    .left {
      width: 780px;
    }

    .right {
      width: 380px;
    }
  }

  .order__container-header {
    .header {
      position: relative;
      z-index: 2;
      background: #fff;

      .header__wrap {
        position: relative;
        z-index: 2;
        background: #fff;
      }

      .header__title {
        width: 1200px;
        margin: 0 auto;
        letter-spacing: 2px;
        font-size: 18px;

        .title {
          display: inline-block;
          padding: 33px 0 26px;
          border-bottom: 2px solid #0049AC;
          color: #0D5CAB;
        }

      }

    }
  }

  .base__section {
    padding: 40px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #fff;

    &.bg {
      padding: 40px 0;
      background: transparent;

      .base__header {
        padding-left: 40px;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .base__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #F2F2F2;

      .header__left {
        font-size: 18px;
        font-weight: bolder;
        color: #222222;
      }

      .header__right {
        .button {
          width: 130px;
          height: 50px;
          border-radius: 25px;
          border-color: #FF2900;
          background: #FF2900;
        }
        .topay{
          position: relative;

        }
       .topay:hover{
            .topay-hover{
            position: absolute;
            display: block;
            height: 50px;
            top:-50px;
            left:-103px;
          }

        }
        .topay-hover{
          display: none;
        }
      }
    }

    .base__content {
      margin-top: 30px;

      .row-margin {
        margin-top: 20px;
      }

      .base__content-item {
        margin-bottom: 18px;
        font-size: 14px;
        color: #666666;

        &:last-child {
          margin-bottom: 0;
        }

        dd, dt {
          display: inline-block;
        }
      }

      .base__step {
        max-height: 400px;
        overflow: auto;

        .description {
          margin-bottom: 20px;
        }

        .empty {
          padding: 78px 82px;

          .img {
            width: 100%;

            img {
              width: 100%;
            }
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            text-align: center;
            color: #ABABAB;
          }
        }
      }
    }
  }

  .detail__info {
    padding: 5px 31px 31px;
    border-radius: 6px;
    margin-bottom: 20px;
    background: #fff;
  }

  .action-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 80px;
  }

  .delivery__info {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 19px;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #fff;

    .delivery__item {
      width: 25%;
      flex-shrink: 0;
      border-right: 1px solid #F4F4F4;
      padding-left: 18px;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .title {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: normal;
      color: #333333;
    }

    .wrap {
      padding-right: 26px;

      .info {
        display: flex;
        margin-bottom: 11px;

        &:last-child {
          margin-bottom: 0;
        }

        dd, dt {
          display: inline-block;
          font-size: 12px;
          color: #333333;
        }

        dd {
          width: 180px;
        }

        dt {
          width: 60px;
          flex-shrink: 0;
          margin-right: 18px;
        }
      }
    }
  }

  .pay__section {
    padding-top: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      .title {
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
        color: #333333;
      }

      .link {
        font-size: 12px;
        line-height: 22px;
        vertical-align: middle;
        color: #666666;

        .icon {
          display: inline-block;
          margin-left: 6px;
          font-size: 15px;
          line-height: 22px;
          vertical-align: middle;
          color: #4981E8;
        }
      }
    }

    .pay__list {
      padding: 13px 16px;
      @include clearfix;

      .item {
        position: relative;
        float: left;
        width: 130px;
        border: 1px solid #dadada;
        margin-right: 15px;
        line-height: 28px;
        text-align: center;

        &.active {
          border-color: #4981E8;

          .icon {
            display: block;
          }
        }

        .link {
          display: block;
          color: #666666;
        }

        .icon {
          display: none;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 14px;
          height: 14px;
          font-size: 12px;
          background: linear-gradient(135deg, #fff 0, #fff 50%, #4981E8 50%);
          color: #fff;

          &::before {
            position: absolute;
            top: 3px;
            right: -2px;
            transform: scale(0.8);
          }
        }
      }
    }
  }

  .goods__info {
    padding: 24px 20px;
    border-radius: 6px;
    background: #fff;

    .title {
      font-size: 14px;
      font-weight: normal;
      color: #333333;
    }

    .goods-list {
      margin: 13px 0 35px;
    }
  }

  .no-traces {
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    margin: 10px 10px;
  }

  .steps-wrap {
    .steps {
      ::v-deep .el-step__head.is-success {
        color: #71B247;
        border-color: #71B247;

        .el-step__icon {
          background: #71B247;
          color: #fff;
        }
      }

      ::v-deep .el-step__head.is-wait {
        color: #CCCCCC;
        border-color: #CCCCCC;

        .el-step__icon {
          background: #CCCCCC;
          color: #fff;
        }
      }

      ::v-deep .el-step__main {
        margin-top: 20px;

        .el-step__title, .el-step__description {
          font-size: 12px;
          line-height: 20px;
          color: #333333;
        }

        .el-step__title {
          margin-bottom: 5px;
        }
      }
    }

  }

  .steps-horizon {
    height: 188px;
    padding: 0 32px;
    margin-top: 80px;
    overflow: auto;

    ::v-deep .el-step__title {
      padding-bottom: 6px;
    }

    ::v-deep .el-step__head.is-finish {
      text-align: center;
      font-size: 0;

      .el-step__icon.is-text {
        width: 11px;
        height: 11px;
        align-items: normal;
        padding: 2px;
        font-size: 0;
        border-color: #DB5C57;
        border-width: 1px;

        &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;

          background: #DB5C57;
        }
      }
    }

    ::v-deep .el-step__head {
      text-align: center;
      font-size: 0;

      .el-step__line {
        background-color: #E6E6E6;
      }

      .el-step__icon.is-text {
        width: 11px;
        height: 11px;
        align-items: normal;
        padding: 2px;
        font-size: 0;
        border-color: #fff;
        border-width: 1px;

        &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;

          background: #E6E6E6;
        }
      }
    }

    ::v-deep .el-step__description.is-finish {
      .label {
        opacity: 1;
      }
    }

    .step-content {
      @include clearfix;

      & > * {
        float: left;
      }

      .label {
        width: 28px;
        margin: 0 6px 0 0;
        border-radius: 1px;
        // line-height: 13px;
        text-align: center;
        background: #FD5C72;
        color: #fff;
        opacity: 0;
      }

      .time {
        margin-right: 45px;
        font-size: 12px;
        color: #333333;
      }

      .description {
        width: 452px;
        font-size: 12px;
        line-height: 20px;
        color: #333333;
      }
    }
  }

  .sum__container {
    padding: 31px 22px 0;

    .list {
      margin-bottom: 12px;
      text-align: right;

      &:last-child {
        margin-bottom: 0;
      }

      dd, dt {
        display: inline-block;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
      }

      .count {
        width: 135px;
        text-align: right;

        &.bold {
          font-weight: bolder;
          font-size: 18px;
          color: #FF2900;
        }

        @include currency;
      }
    }
  }
}
</style>
