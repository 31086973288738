import { render, staticRenderFns } from "./ResultDialog.vue?vue&type=template&id=6a314924&scoped=true&"
import script from "./ResultDialog.vue?vue&type=script&lang=js&"
export * from "./ResultDialog.vue?vue&type=script&lang=js&"
import style0 from "./ResultDialog.vue?vue&type=style&index=0&id=6a314924&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a314924",
  null
  
)

export default component.exports