<!--
 * @Author: Neko
 * @Date: 2021-03-22 14:08:17
 * @LastEditTime: 2021-10-08 16:42:49
 * @LastEditors: Please set LastEditors
-->
<template>
  <el-dialog
    :visible="isShowResultDialog"
    :title="$t('common.payResult')"
    width="500px"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="result-dialog__container">
      <div class="icon" :class="orderStatusType">
        <i :class="'el-icon-' + orderStatusType" />
      </div>

      <div class="text">
        {{ orderStatusText }}
      </div>

      <!--   -->
      <div v-if="status !== 0" class="buttons">
        <router-link class="link" :to="{ name: 'OrderDetail', query: { orderid: orderId } }" replace>
          <el-button class="button">{{ $t('common.seeOrder') }}</el-button>
        </router-link>

        <router-link class="link" :to="{ name: 'Home' }" replace>
          <el-button type="primary" class="button">{{ $t('common.goHome') }}</el-button>
        </router-link>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { useRequest } from '@/utils/request'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { root }) {
    const { $route } = root
    const $t = root.$jst
    let timer = null

    const data = reactive({
      isShowResultDialog: false,
      status: 0,
      orderId: $route.query.orderid,

      orderStatusType: computed(() => {
        switch (data.status) {
          case 1:
            return 'success'

          case 0:
            return 'info'

          case -1:
            return 'error'
        }
      }),

      orderStatusText: computed(() => {
        switch (data.status) {
          case 1:
            return $t('common.paySus')

          case 0:
            return $t('common.getPsySus')

          case -1:
            return $t('common.payFail')
        }
      })
    })

    watch(() => props.visible, res => {
      data.isShowResultDialog = res

      if (data.isShowResultDialog) {
        window.clearTimeout(timer)
        getOrderStatusFetch()
      }
    })

    onMounted(() => {
      data.isShowResultDialog = props.visible

      if (data.isShowResultDialog) {
        getOrderStatusFetch()
      }
    })

    onUnmounted(() => {
      window.clearTimeout(timer)
    })

    const { fetch: getOrderStatusFetch } = useRequest('order/getOrderPayStatusAction', {
      data: computed(() => ({
        orderId: data.orderId
      })),

      immediate: false,
      onSuccess(res) {
        if (res.data === 0) {
          timer = window.setTimeout(() => {
            getOrderStatusFetch()
          }, 2000)
        } else {
          window.clearTimeout(timer)
          timer = null
          data.status = res.data
        }
      }
    })

    onMounted(() => {
      // getOrderStatusFetch()
    })

    return {
      ...toRefs(data)
    }
  }
})
</script>

<style lang="scss" scoped>
.result-dialog__container {
  .icon {
    margin-bottom: 20px;
    font-size: 48px;
    text-align: center;

    &.success {
      color: #0049AC;
    }

    &.info {
      color: #0049AC;
    }

    &.error {
      color: #0049AC;
    }
  }

  .text {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    color: #222;
  }

  .buttons {
    text-align: center;

    .link {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      border-radius: 25px;
      border-color: #0049AC;
      background: #0049AC;
      color: #fff;
    }
  }
}
</style>
