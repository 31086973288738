<template>
  <div class="container">
    <el-dialog
      :visible="checkoutInfo.checkoutPayDialog"
      title="请输入信用卡号与有效期"
      width="30%"
      @close="closeCheckoutPayDialog"
    >
      <!-- iframe -->
      <iframe :src="'/html/checkout.html?orderId='+ checkoutInfo.orderId + '&publicKey=' + checkoutInfo.publicKey + '&url=' + checkoutInfo.url + '&token=' + checkoutInfo.token " style="width: 100%;" frameborder="0" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    checkoutInfo: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    closeCheckoutPayDialog() {
      this.$emit('closeCheckout')
    }

  }
}
</script>

<style scoped lang="scss">

</style>

